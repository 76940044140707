import { datadogRum } from '@datadog/browser-rum';
import { Icon, IconMagnifier, IconNotification } from '@flixbus/honeycomb-icons-react';
import {
  Autocomplete,
  AutocompleteInput,
  AutocompleteOptions,
  Button,
  Grid,
  GridCol,
  Header,
  HeaderBrand,
  HeaderBurgerMenu,
  HeaderNavigation,
  HeaderWidgets,
  MainWrapper,
  NavItem,
  ThemeWrapper,
  themeFlixDark,
} from '@flixbus/honeycomb-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { NavLink, Link as RouterLink, useHistory } from 'react-router-dom';

import logo from '../../FlixBus.svg';
import useApiService from '../../hooks/useApiService';
import useFlowService from '../../hooks/useFlowService';
import { useIsInternalUser } from '../../hooks/useIsInternalUser';
import { capitalize } from '../../utils/wordUtils';
import Footer from './Footer';
import './Layout.css';

datadogRum.init({
  applicationId: '92f521ef-4197-4b3b-944d-05f65897b446',
  clientToken: 'pub7bb4ee29a5abe14154dd3f7ab206bbb6',
  site: 'datadoghq.eu',
  service: 'fxt.platform.api.developer-portal-ui',
  env: process.env.REACT_APP_DATADOG_RUM_ENV,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

function Layout(props) {
  const [loading, setLoading] = React.useState(false);
  const inputEl = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchResults, setSearchResults] = useState([]);
  const history = useHistory();
  const isInternalUser = useIsInternalUser();
  const { getApis, getOperations } = useApiService();
  const { getFlows } = useFlowService();

  const queryOptions = {
    enabled: !!searchQuery,
    onSuccess: (data) => {
      updateSearchResults(data);
    },
    refetchOnWindowFocus: false,
  };

  // Below queries are fired through global search
  const { data: apis } = useQuery([`apiSearch-${searchQuery}`, searchQuery], () => getApis(searchQuery), queryOptions);

  useQuery([`flowSearch-${searchQuery}`, searchQuery], () => getFlows(searchQuery), queryOptions);

  useQueries({
    queries: apis
      ? apis.data.map((api) => {
          return {
            queryKey: [api],
            queryFn: () => getOperations(api.id, searchQuery),
            onSuccess: (data) => {
              updateSearchResults(data);
            },
            refetchOnWindowFocus: false,
          };
        })
      : [], // if apis is undefined, an empty array will be returned
  });

  const updateSearchResults = (response) => {
    let results = searchResults.slice();
    response?.data.forEach((resource) => {
      const shouldAppend =
        results.find((existingResource) => existingResource.title === (resource?.name || resource?.id)) === undefined;
      if (shouldAppend) {
        results.push({
          title: getTitle(resource),
          subtitle: resource.description,
          url: getURL(resource),
        });
      }
    });

    setSearchResults(results);
    setLoading(false);
  };

  const search = (event) => {
    const keyword = event.target.value.trim();
    const keywordMinLength = 2;
    if (keyword.length > keywordMinLength) {
      setLoading(true);
      setSearchQuery(keyword);
      setSearchResults([]);
    }
  };

  const MainNavigation = (
    <HeaderNavigation aria-label="Main site">
      <NavItem to="/flows" RouterLink={NavLink}>
        Flows
      </NavItem>
      <NavItem to="/apis" RouterLink={NavLink}>
        APIs
      </NavItem>
      {isInternalUser && (
        <NavItem to="/preview" RouterLink={NavLink}>
          Spec Preview
        </NavItem>
      )}
      {!isInternalUser && (
        <NavItem href="https://flixtech.atlassian.net/servicedesk/customer/portal/124" target="_blank">
          Service Desk
        </NavItem>
      )}
    </HeaderNavigation>
  );

  return (
    <ThemeWrapper theme={props.theme} themes={{ 'flix-dark': themeFlixDark }}>
      <MainWrapper full extraClasses="layout">
        <Header
          fullwidth={true}
          fixed={true}
          brand={
            <HeaderBrand alt="Flix Developer Portal" href="/" src={logo} width="126" height="24" RouterLink={NavLink} />
          }
          navigation={MainNavigation}
          burgerMenu={
            <HeaderBurgerMenu openPanelLabel="Open main menu" closePanelLabel="Close main menu" panelId="burger-panel">
              {MainNavigation}
            </HeaderBurgerMenu>
          }
          widgetSize="m"
          widgets={
            <HeaderWidgets noResponsiveRendering extraClasses="search-widget">
              <Grid>
                <GridCol inline>
                  <Button appearance="link" to="/news" RouterLink={RouterLink}>
                    <Icon InlineIcon={IconNotification} aria-label="Opens News" />
                    News
                  </Button>
                </GridCol>
                <GridCol>
                  <form aria-label="Search" style={{ display: 'grid', alignItems: 'center', height: '100%' }}>
                    <Autocomplete
                      onChange={(e) => {
                        setLoading(true);
                      }}
                      onDebounce={search}
                      onSelect={(option) => {
                        history.push(option.url);
                      }}
                      options={searchResults}
                    >
                      {/* configures input field appearance */}
                      <AutocompleteInput
                        id="autocomplete"
                        placeholder=""
                        aria-label="Search"
                        loading={loading}
                        type="search"
                        innerRef={inputEl}
                        iconLeft={<Icon InlineIcon={IconMagnifier} />}
                      />
                      {/* displays a dropdown with options */}
                      <AutocompleteOptions label="Search results" optionsToDisplay={5} optionHasSubtitle />
                    </Autocomplete>
                  </form>
                </GridCol>
              </Grid>
            </HeaderWidgets>
          }
        />
        <section className="layout-content">{props.children}</section>
        <Footer theme={props.theme} themeToggleHandler={props.themeToggleHandler} extraClasses="footer" />
      </MainWrapper>
    </ThemeWrapper>
  );
}

function getTitle(resource) {
  return `${capitalize(resource.type)}: ${resource.hasOwnProperty('name') ? resource.name : resource.id}`;
}

function getURL(resource) {
  if (resource.type === 'api') {
    return `/apis/${resource.id}`;
  } else if (resource.type === 'flow') {
    return `/flows/${resource.id}/overview`;
  } else {
    return `/apis/${resource.parent}/reference#operation/${resource.id}`;
  }
}

export default Layout;
