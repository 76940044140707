import Header from '../legal/Header';
import FaqList from './FaqList';

function Faq() {
  return (
    <>
      <Header
        breadcrumbs={[
          { name: 'Home', link: '/' },
          { name: 'FAQ', link: '/faq' },
        ]}
        title="FAQ"
      />
      <main className="faq-page">
        <FaqList />
      </main>
    </>
  );
}

export default Faq;
