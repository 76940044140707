import { Icon, IconArrowBigDown } from '@flixbus/honeycomb-icons-react';
import { Box, Fieldset, Grid, GridCol, Heading, Tag } from '@flixbus/honeycomb-react';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link as RouterLink } from 'react-router-dom';

import MarkdownRenderer from '../../utils/MarkdownRenderer';

function FlowStep(props) {
  const isOptionalStep = props.step.isOptional;
  return (
    <>
      <Grid align="center">
        <GridCol key="grid-a" size={8}>
          <Box extraClasses={isOptionalStep ? 'flow-step-optional' : 'flow-step'}>
            <Grid align="top">
              <GridCol key="grid-b">
                <Fieldset horizontal itemSpacing={'1'}>
                  <Tag key={props.step.endpoint.method} extraClasses="flow-step-method">
                    {props.step.endpoint.method}
                  </Tag>
                  <RouterLink to={props.step.refLink} className="flow-step-path">
                    <Tag key={props.step.endpoint.path} extraClasses="flow-step-path" display="outlined">
                      {props.step.endpoint.path}
                    </Tag>
                  </RouterLink>
                  {isOptionalStep && (
                    <Tag display="outlined" small extraClasses="flow-step-optional-tag">
                      Optional
                    </Tag>
                  )}
                </Fieldset>
                <ReactMarkdown
                  children={props.step.description}
                  components={{
                    code: (props) => <MarkdownRenderer {...props} />,
                  }}
                />
              </GridCol>
            </Grid>
          </Box>
        </GridCol>
        <GridCol key="grid-c" size={3}>
          <Box extraClasses="flow-step-names">
            <Grid align="top">
              <GridCol key="grid-d">
                <Heading size={4} extraClasses="flow-step-info">
                  {props.step.name}
                </Heading>
              </GridCol>
            </Grid>
          </Box>
        </GridCol>
      </Grid>
      {props.hasNextStep && (
        <Icon InlineIcon={IconArrowBigDown} size={6} extraClasses="hcr-space-2-bottom hcr-space-4-left" />
      )}
    </>
  );
}

export default FlowStep;
