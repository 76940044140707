import { Breadcrumbs, BreadcrumbsItem } from '@flixbus/honeycomb-react';
import * as React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { getAPINameFromId } from '../../utils/wordUtils';
import './ApiReference.css';

function ApiReferenceHeader(props) {
  const { id } = useParams();

  return (
    <div className="api-reference-header">
      <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-top hcr-space-2-bottom hcr-space-2-left">
        <BreadcrumbsItem RouterLink={RouterLink} to="/">
          Home
        </BreadcrumbsItem>
        <BreadcrumbsItem RouterLink={RouterLink} to="/apis">
          APIs
        </BreadcrumbsItem>
        <BreadcrumbsItem RouterLink={RouterLink} to={`/apis/${id}`}>
          {getAPINameFromId(id)}
        </BreadcrumbsItem>
        <BreadcrumbsItem RouterLink={RouterLink} to={`/apis/${id}/reference`}>
          Reference
        </BreadcrumbsItem>
      </Breadcrumbs>
    </div>
  );
}

export default ApiReferenceHeader;
