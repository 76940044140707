import useApiClient from './useApiClient';

const useFaqService = () => {
  const { apiClient } = useApiClient();

  const getAllFaqs = () => {
    return apiClient().then((client) => client.get('/faqs'));
  };

  return {
    getAllFaqs: getAllFaqs,
  };
};

export default useFaqService;
