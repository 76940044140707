import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Link } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useApiService from '../../hooks/useApiService';
import useFlowService from '../../hooks/useFlowService';

function Contact(props) {
  const { id } = useParams();

  const { getApi } = useApiService();
  const { getFlow } = useFlowService();

  const {
    data: response,
    error,
    isError,
    isLoading,
  } = useQuery([`${props.type}${id}`], async () => (props.type === 'flow' ? getFlow(id) : getApi(id)), {
    retry: (failureCount, { response }) => {
      return response.status !== 404;
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isError) {
    let helpdeskLink = '';
    if (props.type === 'flow') {
      helpdeskLink = 'https://flixtech.atlassian.net/servicedesk/customer/portal/124'; //TODO get helpdeskLink from Flow
    } else {
      helpdeskLink = response.data.providers[0].contact.helpdesk;
    }

    return (
      <>
        <Link target="_blank" href={helpdeskLink}>
          Get help <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
        </Link>
      </>
    );
  } else {
    console.log(error);
  }

  return <></>;
}

export default Contact;
