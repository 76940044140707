import * as honeycombIcons from '@flixbus/honeycomb-icons-react';
import { Icon } from '@flixbus/honeycomb-icons-react';
import { Grid, GridCol, Heading, ListWrapper, ListWrapperItem, Text } from '@flixbus/honeycomb-react';
import { Link as RouterLink } from 'react-router-dom';

import { capitalize } from '../../utils/wordUtils';
import './Flow.css';

function Flow(props) {
  //handle missing icon
  let icon = props.data.icon;

  const flowIcon = isValidIcon(icon) ? honeycombIcons[`Icon${capitalize(icon)}`] : honeycombIcons.IconTicket;

  return (
    <ListWrapper extraClasses="flow">
      <ListWrapperItem to={`/flows/${props.data.id}/overview`} RouterLink={RouterLink}>
        <Grid align="top">
          <GridCol inline extraClasses="flow-icon-container">
            <Icon InlineIcon={flowIcon} size={6} style={{ fill: 'var(--hcr-brand-primary-color)' }}></Icon>
          </GridCol>
          <GridCol extraClasses="flow-description-container">
            <Heading flushSpace size={3} sectionHeader={true}>
              {props.data.name}
            </Heading>
            <Text extraClasses="hcr-space-flush-bottom">{props.data.description}</Text>
          </GridCol>
        </Grid>
      </ListWrapperItem>
    </ListWrapper>
  );
}

function isValidIcon(icon) {
  return icon !== '' && honeycombIcons[`Icon${capitalize(icon)}`] !== undefined;
}

export default Flow;
