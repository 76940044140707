import { Icon, IconNewTab } from '@flixbus/honeycomb-icons-react';
import { Link, Text } from '@flixbus/honeycomb-react';

// Regex patterns for different tags
const paragraphRegex = /<p>(.*?)<\/p>/g;
const anchorTagRegex = /<a href="([^"]+)"[^>]*>(.*?)<\/a>/g;
const strongTagRegex = /<strong>(.*?)<\/strong>/g;
const preTagRegex = /<pre>(.*?)<\/pre>/g;

// Function to render <a> tags
const renderAnchorTags = (htmlText) => {
  // Split the htmlText and replace the anchors
  const parts = htmlText.split(anchorTagRegex);

  return parts.map((part, index) => {
    if (index % 3 === 0) {
      // Plain text part
      return part;
    }
    if (index % 3 === 1) {
      // URL part (from the href attribute)
      const url = part;
      const text = parts[index + 1]; // Text between the <a> tags

      // Check if the URL is a 'mailto:' link
      if (url.startsWith('mailto:')) {
        return (
          <Link key={index} href={url}>
            {text} <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
          </Link>
        );
      } else {
        // Regular 'https' link
        return (
          <Link key={index} target="_blank" href={url}>
            {text} <Icon style={{ fill: 'currentColor' }} InlineIcon={IconNewTab} aria-label="Opens in a New Tab" />
          </Link>
        );
      }
    }
    return null;
  });
};

// Function to render <strong> tags
const renderStrongTags = (htmlText) => {
  const parts = [];
  let match;
  let lastIndex = 0;

  while ((match = strongTagRegex.exec(htmlText)) !== null) {
    if (match.index > lastIndex) {
      parts.push(htmlText.slice(lastIndex, match.index)); // Plain text before <strong>
    }

    parts.push(
      <Text key={`strong-${lastIndex}`} Elem="strong" extraClasses="boldtext">
        {match[1]}
      </Text>
    );

    lastIndex = strongTagRegex.lastIndex;
  }

  if (lastIndex < htmlText.length) {
    parts.push(htmlText.slice(lastIndex)); // Remaining plain text after <strong>
  }

  return parts;
};

// Function to render <pre> tags
const renderPreTags = (htmlText) => {
  const parts = [];
  let match;
  let lastIndex = 0;

  while ((match = preTagRegex.exec(htmlText)) !== null) {
    if (match.index > lastIndex) {
      parts.push(htmlText.slice(lastIndex, match.index)); // Plain text before <pre>
    }

    let preContent = match[1]
      .replace(/&quot;/g, '"')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>'); // Process special characters

    // Replace the escaped newlines '\\n' with actual newlines '\n'
    preContent = preContent.replace(/\\n/g, '\n');

    // Capture the current value of lastIndex
    const currentLastIndex = lastIndex;

    // Split content by actual newline characters
    const preLines = preContent.split('\n').map((line, index) => {
      return (
        <Text key={`pre-${currentLastIndex}-${index}`} Elem="pre">
          {line}
        </Text>
      );
    });

    parts.push(...preLines); // Add each line wrapped in <Text>

    lastIndex = preTagRegex.lastIndex;
  }

  if (lastIndex < htmlText.length) {
    parts.push(htmlText.slice(lastIndex)); // Remaining plain text after <pre>
  }

  return parts;
};

// Function to process text and apply all tag-specific renderers
const renderContent = (htmlText) => {
  let content = renderAnchorTags(htmlText);
  content = content.flatMap((part) => (typeof part === 'string' ? renderStrongTags(part) : part));
  content = content.flatMap((part) => (typeof part === 'string' ? renderPreTags(part) : part));

  return content;
};

// Main function to process paragraphs <p> tags
const htmlRenderer = (htmlText) => {
  const paragraphs = htmlText.split(paragraphRegex);
  return paragraphs.map((paragraph, index) => {
    if (!paragraph) return null;
    return (
      <Text key={`paragraph-${index}`} Elem="p">
        {renderContent(paragraph)}
      </Text>
    );
  });
};

export default htmlRenderer;
