import { Heading, PageContainer, Text } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import useFaqService from '../../hooks/useFaqService';
import htmlRenderer from '../../utils/htmlRenderer';

function FaqList(props) {
  const { getAllFaqs } = useFaqService();
  const { data: response, error, isError, isLoading } = useQuery(['faq'], () => getAllFaqs());

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <div>
        <Text>FAQs could not be loaded: {error.message}</Text>
      </div>
    );
  }

  return (
    <div>
      {response.data.map((faq, index) => {
        return (
          <PageContainer key={index} className="faq">
            <Heading key={index} size={3}>
              {faq.question}
            </Heading>
            {htmlRenderer(faq.answer)}
          </PageContainer>
        );
      })}
    </div>
  );
}

export default FaqList;
