import * as honeycombIcons from '@flixbus/honeycomb-icons-react';
import { Icon } from '@flixbus/honeycomb-icons-react';
import { Grid, GridCol, Heading, ListWrapper, ListWrapperItem, Tag, Text } from '@flixbus/honeycomb-react';
import { Link as RouterLink } from 'react-router-dom';

import { isValidIcon } from '../../utils/honeycombUtils';
import { capitalize, getAPIDescription } from '../../utils/wordUtils';
import './Api.css';

function Api(props) {
  let providers = props.data.providers;
  const providersTags = [];
  if (providers.length > 1) {
    providersTags.push(providers[0]);
    providersTags.push(`+${providers.length - 1}`);
  } else {
    providersTags.push(providers[0]);
  }

  //handle missing icon
  let icon = props.data.icon;
  if (icon === '') {
    icon = 'bus';
  }

  const apiIcon = isValidIcon(icon) ? honeycombIcons[`Icon${capitalize(icon)}`] : honeycombIcons.IconBus;

  return (
    <ListWrapper extraClasses="api">
      <ListWrapperItem to={`/apis/${props.data.name.toLowerCase()}`} RouterLink={RouterLink}>
        <Grid align="top">
          <GridCol inline extraClasses="api-icon-container">
            <Icon InlineIcon={apiIcon} size={6} style={{ fill: 'var(--hcr-brand-primary-color)' }}></Icon>
          </GridCol>
          <GridCol extraClasses="api-description-container">
            <section className="api-description-name-tag-group">
              <Heading flushSpace size={3} sectionHeader={true} extraClasses="api-name hcr-space-1-right">
                {props.data.name}
              </Heading>
              {providersTags.map((tag) => {
                return (
                  <Tag key={tag} small extraClasses="api-teams-tags hcr-space-1-bottom">
                    {tag}
                  </Tag>
                );
              })}
            </section>
            <Text extraClasses="hcr-space-flush-bottom">{getAPIDescription(props.data.description)}</Text>
          </GridCol>
        </Grid>
      </ListWrapperItem>
    </ListWrapper>
  );
}

export default Api;
