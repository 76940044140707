import { Box, Grid, GridCol, Heading, Text } from '@flixbus/honeycomb-react';
import * as React from 'react';

import Artifact from './Artifact';

function ArtifactList(props) {
  const technology = props.artifactList.technology;

  const isPostman = technology.toLowerCase() === 'postman';
  const postmanDisclaimer = (
    <GridCol extraClasses={'hcr-space-2-top'} size={12}>
      <Text>In order to use these files makes sure to have installed Postman.</Text>
    </GridCol>
  );

  return (
    <Box>
      <section>
        <Grid>
          <GridCol size={12}>
            <Heading flushSpace size={2}>
              {technology}
            </Heading>
            <Grid>
              {props.artifactList.artifacts.map((artifact) => {
                return (
                  <GridCol key={artifact.link} size={6}>
                    <Artifact isPostman={isPostman} artifact={artifact} />
                  </GridCol>
                );
              })}
            </Grid>
            {isPostman && postmanDisclaimer}
          </GridCol>
        </Grid>
      </section>
    </Box>
  );
}

export default ArtifactList;
