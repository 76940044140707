import useApiClient from './useApiClient';

const useNewsService = () => {
  const { apiClient } = useApiClient();

  const getAllNews = () => {
    return apiClient()
      .then((client) => client.get('/news'))
      .then((response) => {
        // Sort the response data by 'date' (descending) and 'title' (ascending)
        return {
          ...response,
          data: response.data.sort((a, b) => b.date.localeCompare(a.date) || a.title.localeCompare(b.title)),
        };
      });
  };

  return {
    getAllNews: getAllNews,
  };
};

export default useNewsService;
