import { useQuery } from '@tanstack/react-query';

import useApiService from './useApiService';

export default function useGlobalHelpLink() {
  const { getApi } = useApiService();
  const defaultHelpLink = 'https://flixtech.atlassian.net/servicedesk/customer/portal/124';

  const id = 'meta-api';

  const { data: response, error, isError, isLoading } = useQuery([`api${id}`], () => getApi(id));

  if (isLoading || !response) {
    return defaultHelpLink;
  }

  if (isError) {
    if (error.message.includes('403')) {
      return defaultHelpLink;
    }
  }

  const helpdeskContact = response.data.providers?.[0]?.contact?.helpdesk;
  return helpdeskContact || defaultHelpLink;
}
