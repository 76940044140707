export function isMethod(methodName) {
  let name = methodName.toUpperCase();
  switch (name) {
    case 'GET':
    case 'POST':
    case 'PUT':
    case 'DELETE':
    case 'OPTIONS':
    case 'HEAD':
    case 'PATCH':
    case 'TRACE':
      return true;
    default:
      return false;
  }
}

export function getBasepath(urlPath) {
  if (urlPath.startsWith('/')) {
    urlPath = urlPath.substring(1);
  }
  let i = urlPath.indexOf('/');

  return `/${urlPath.substring(0, i)}`;
}

export const slugify = (title) =>
  title
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .trim();
