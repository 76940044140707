import { Heading, NavItem, NavSide } from '@flixbus/honeycomb-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import useApiService from '../../hooks/useApiService';
import { createApiOverview } from '../../utils/apiUtils';
import ServiceList from './ServiceList';

function SecondaryNav() {
  const { id } = useParams();

  const { getApi } = useApiService();
  const { data: response, error, isError, isLoading } = useQuery([`api${id}`], () => getApi(id));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>API could not be loaded: {error.message}</div>;
  }

  const apiOverview = createApiOverview(response.data);

  return (
    <>
      <Heading size={3}>Basics</Heading>
      <NavSide aria-label="Api resource links">
        <NavItem key="getting-started" to={`/apis/${id}/getting-started`} RouterLink={NavLink}>
          Getting started
        </NavItem>
        <NavItem key="changelog" to={`/apis/${id}/changelog`} RouterLink={NavLink}>
          Changelog
        </NavItem>
      </NavSide>
      {Object.entries(apiOverview).map((entry) => {
        const serviceName = entry[0];
        const endpoints = entry[1];
        return <ServiceList key={serviceName} title={serviceName} endpoints={endpoints} id={id} />;
      })}
    </>
  );
}

export default SecondaryNav;
